.custom-select {
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  transition: all 0.2s linear;
  position: relative;

  .react-checkbox-tree > ol {
    margin-block: 24px;
  }

  .rct-collapse.rct-collapse-btn {
    border: none;
    background-color: transparent;
    padding: 0;
    padding-right: 8px;
  }

  .rct-title {
    margin-left: 12px;
  }

  .rct-node.rct-node-parent {
    ol {
      padding-left: 22px !important;
    }
  }

  .rct-text {
    display: flex;
    align-items: center;

    label {
      display: flex;
      align-items: center;
    }
  }

  .rct-collapse.rct-collapse-btn {
    border: none;
    background-color: transparent;
    color: var(--dark) !important;
    padding: 0;
    padding-right: 8px;
  }

  .rct-title {
    margin-left: 8px;
  }

  .rct-node {
    list-style-type: none;

    &:last-child {
      padding-bottom: 0;
    }

    input {
      width: 16px;
      height: 16px;
    }
  }

  .rct-text {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    label {
      display: flex;
      align-items: center;
    }

    :not(button) ~ label {
      padding-left: 20px;
    }
  }

  &:focus-within {
    border: 1px solid var(--primary);
    box-shadow: 0px 0px 4px rgba(24, 7, 103, 0.25);
  }

  .separator {
    height: calc(100% - 14px) !important;
    width: 1px;
    background-color: #d9d9d9;
  }

  input {
    outline: none;
    border: none !important;
    font-size: 14px;
    line-height: 21px;
    padding-block: 6.5px;
    background-color: transparent;

    &:placeholder-shown {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .list-selected {
    gap: 8px 12px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #d9d9d9;
    }
  }

  .item-selected {
    padding: 8.5px;
    padding-left: 20px;
    background: #e5f4fc;
    border-radius: 50px;
    max-width: 100%;

    .name {
      font-size: 14px;
      line-height: 17px;
      margin-right: 10px;
      word-break: break-all;
    }

    img {
      $size: 22px;
      width: $size;
      height: $size;
    }
  }

  .drop-menu {
    padding-right: 10px;
  }

  .list-item {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;
    max-height: 40vh;
    background-color: #fff;
    border: 1px solid #c9c9c9;
    overflow-y: scroll;
    overflow-y: overlay;
    border-radius: 10px;
    z-index: 1000;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);

    .item {
      font-size: 14px;
      line-height: 17px;
    }
    .item.no-item:hover {
      background-color: transparent;
    }

    .item:not(.no-item):hover {
      background-color: #e5f4fc;
      color: var(--primary);
    }
  }
}
