.order-status-filter {
  select {
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    background-image: url('data:image/svg+xml;utf8,<svg fill=%27black%27 height=%2724%27 viewBox=%270 0 24 24%27 width=%2724%27 xmlns=%27http://www.w3.org/2000/svg%27><path d=%27M7 10l5 5 5-5z%27/><path d=%27M0 0h24v24H0z%27 fill=%27none%27/></svg>');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 6px;
  }
}

.search-btn {
  position: relative;
  border-radius: 30px;
  background: transparent;
  max-width: 500px;

  input[type='text'] {
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1); */
    border-radius: 30px;
    padding: 11px 24px 11px 48px;
    border: none;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(0, 0, 0);
    min-width: 240px;
    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .close-icon {
    left: unset;
    right: 0;
  }

  svg {
    position: absolute;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.4);
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: 300;
    &.reset-search {
      right: 10px;
    }
  }
}
