.tabs {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  .tab-wrapper {
    margin-top: 52px;
    padding: 0;
  }
  .page-status {
    position: relative;
    padding-left: 26px;
    &::after {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: currentColor;
      left: 0;
      top: 9px;
    }
  }
}

.form-check-input[type='checkbox'] {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-color: var(--black) !important;
  background-color: var(--white);
  display: inline-block;
  cursor: pointer;

  &:hover:not(:checked) {
    background-color: #ccc;
  }
}

.form-control--short {
  max-width: 90px !important;
}

.previous-link-wrap {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.previous-link {
  padding-block: 12.5px !important;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2)) !important;
  transition: color 0.2s linear, background-color 0.2s linear, border-color 0.2s linear !important;

  &:hover {
    color: var(--white) !important;
    background-color: var(--primary-hover) !important;
    border-color: var(--primary-hover);
  }
}

// table payment
.table-payment {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;

  tr:nth-child(odd) {
    background-color: #f1f1f1;
  }
}

.order__info-wrap {
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: fit-content;
  gap: 8px 40px;
}

.ticket {
  &--header {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

    &_group span {
      width: 85px;
    }
  }

  &--footer {
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
    padding-top: 18px;
  }
}

.purchased-form {
  position: relative;
  top: -16px;
}

.receipt-icon {
  .circle {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
  }
}

// Invoice CSS
.info-table .ticket {
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;

  &--header {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      width: calc(100% - 48px);
      border-bottom: 1px dashed #eaeaea;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &_group {
      a {
        color: #4285f4;
      }
    }
  }

  &--body {
    position: relative;
    &::after,
    &::before {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 50% 50% 0 0 / 100% 100% 0 0;
      background-color: #f5f7fb;
    }
    &::after {
      top: -8px;
      left: -8px;
      transform: rotate(90deg);
    }
    &::before {
      top: -8px;
      transform: rotate(-90deg);
      right: -8px;
    }

    &__total {
      position: absolute;
      right: 24px;
      bottom: 18px;
      font-size: 18px;
      line-height: 22px;
      span {
        font-style: 18px;
        line-height: 22px;
      }
    }
  }
}
// End Invoice CSS

// Information CSS
.cell-item {
  padding-left: 41px;
}

.note-textarea {
  resize: none !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 80px;
  outline: none;
}

.shipping-table {
  background-color: #f5f7fb !important;
  border-bottom: none !important;
}
// End Information CSS

.table--item {
  &:hover {
    background-color: #f0f0f0 !important;
  }
  &:nth-child(2n) {
    background-color: #f5f9fe;
  }
  td {
    border: none;
  }
}
.backlink-icon {
  position: relative;
  left: -8px;
}

.info-table {
  overflow-x: auto;

  table {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .input-qty-to-refund {
    width: 170px;
  }

  .sku-product,
  .option-product {
    font-size: 13px;
    line-height: 21px;
    font-style: italic;
  }

  .return-stock span {
    display: inline-block;
    min-width: 60px;
  }

  thead {
    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
  }

  tbody {
    tr {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    }
    tr:last-child {
      border-bottom: none;
    }
    td {
      padding-block: 24px !important;
      border-bottom: none;
    }
  }
}

.order-table {
  thead {
    td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  tbody {
    tr {
      border-bottom: none;
      &:first-child {
        border-top: none;
      }
      &:hover {
        background-color: #f0f0f0;
      }
      td {
        padding: 20px 0;
        border-bottom: none;
        border-top: none;
      }
    }
  }
}
.notes-wrapper {
  height: 50vh;
  max-height: 100vh;
  overflow-y: auto;
}
.schedule-wrapper {
  height: 50vh;
  max-height: 100vh;
  overflow-y: auto;
  .schedule-item {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid var(--primary);
      left: 0;
      top: 5px;
    }
    &:not(:first-child)::after {
      background-color: var(--primary);
    }
    &:not(:last-child)::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      background-color: var(--primary);
      border-radius: 5px;
      left: 3px;
      top: 14px;
    }
  }
}

.info-shipping {
  table {
    tbody {
      tr {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
        &:last-child {
          border: none;
        }
      }
    }
  }
}
.pills {
  padding: 8px 16px;
  border-radius: 20px;
  &-danger {
    background: rgba(237, 83, 72, 0.2);
    color: var(--danger);
  }
  &-success {
    background: rgba(127, 188, 2, 0.2);
    color: var(--success);
  }
  &-warning {
    background: rgba(255, 176, 98, 0.2);
    color: var(--warning);
  }
}

.tracks-table {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;

  .icon-trash {
    transition: color 0.2s linear;
    cursor: pointer;

    &:hover {
      color: var(--danger);
    }
  }

  thead {
    tr {
      background: rgba(192, 194, 194, 0.2);
    }
  }
  tbody {
    tr {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 24px;
        width: calc(100% - 48px);
        height: 1px;
      }
      &:last-child {
        &::after {
          content: '';
          border-bottom: none;
        }
      }
    }
  }
}

.notes-title {
  margin-bottom: 24px;
}

.notes {
  &--icon {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
  }
}

.time-at-comment {
  $space: 10px;
  padding-top: $space;
  margin-top: $space;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  width: fit-content;
}

@media screen and (max-width: 1440px) {
  .schedule-wrapper {
    margin-top: 24px;
  }

  .table-payment td:not(.td-label) {
    word-break: break-all;
  }
}

@media screen and (max-width: 1280px) {
  .info-table {
    .input-qty-to-refund {
      max-width: 90px;
    }
  }
}
