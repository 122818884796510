@import 'override';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

body {
  overflow-y: auto;
  overflow-y: overlay;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(245, 247, 251, 1);
}

// my own spacer's map
:root {
  --bs-body-font-family: 'Poppins', sans-serif;
  --bs-gradient: none;
  --bs-body-color: #{$dark};
  --dark: #{$dark};
  --white: #{$white};
  --primary: #{$primary};
  --primary-hover: #{$primary_hover};
  --secondary: #{$secondary};
  --info: #{$info};
  --danger: #{$danger};
  --warning: #{$warning};
  --success: #{$success};
  --cancel: #{$cancel};
  --disabled: #{$disabled};
  --link: #{$link};
  --link-hover: #{$link-hover};
  --bs-link-color: #{$primary_hover};
  --bs-link-hover-color: #{$link};
  --bs-btn-active-color: #{$white};
  --disabled-color: #{$disabled};
  --width-title-coupon: #{$width-title-coupon};
  --border-color: #{$border-color};
}

// Custom scrollbar
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: #ebebeb;
  border-radius: 15px;
}
::-webkit-scrollbar:horizontal {
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: rgb(197, 197, 197);
  border-radius: 10px;
}
