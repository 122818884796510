#create-edit__product .img-video {
  padding-inline: 64px !important;
  .border-rd-10 {
    border-radius: 10px;
  }

  .title {
    white-space: nowrap;
  }

  .video-content {
    $height-input: 46px;
    .title {
      width: 130px;
      flex-shrink: 0;
      margin-bottom: 0;

      &.center {
        height: fit-content;
        position: relative;
        top: calc($height-input / 2);
        transform: translateY(-50%);
      }
    }
  }

  .video-wrap {
    flex-grow: 1;
    max-width: 640px;
    height: 360px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    svg {
      font-size: 40px;
      color: #ccc;
    }
  }

  .video {
    z-index: 1;
  }

  .image-large {
    object-fit: contain;
    width: 40%;
    height: auto;
    max-height: 600px;
  }

  .list-role {
    width: 400px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .close-icon {
    font-size: 26px;
    transition: color 0.2s linear;

    &:hover {
      color: var(--danger);
    }
  }

  .done-wrap {
    color: var(--primary);
    position: relative;

    .button {
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: currentColor;
      opacity: 0.05;
    }
  }

  .item-select {
    position: relative;
    width: 150px;
    border-radius: 10px;

    .image.preview {
      border-radius: inherit;
    }

    .remove {
      position: absolute;
      top: 8px;
      right: 8px;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .image {
    width: 100%;
    height: 150px;
    object-fit: cover;

    .preview {
      height: unset;
      max-height: 200px;
    }
  }

  .upload {
    background: #ffffff;
    border: 1px dashed #4285f4;
    border-radius: 5px;
    $size: 150px;
    width: $size;
    height: $size;

    .upload-img {
      object-fit: contain;
      color: #ccc;
    }

    span {
      color: rgba(0, 0, 0, 0.2);
    }
  }
}
