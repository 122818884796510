.login {
  .sub-title {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 17px;

    span {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .input-list {
    display: grid;
    row-gap: 16px;
  }

  .message {
    font-size: 14px;
    line-height: 17px;
    max-width: 400px;
    margin-inline: auto !important;
  }

  h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 0.0025em;
    color: #ffffff;
    margin-bottom: 40px;
  }

  h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #ffffff;
  }

  .form-wrap {
    background: transparent;
    width: 100%;
    box-shadow: none;
  }

  .input-list,
  .button-form {
    max-width: 400px;
    margin: 0 auto;
    display: block;
  }
}
