#search-optimize {
  $height-input: 46px;

  &.optimize-list {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px 24px;
  }

  .optimize-label {
    position: relative;
    top: calc($height-input / 2);
    height: fit-content;
    transform: translateY(-50%);
  }

  .optimize-control {
    height: $height-input;
    max-width: 700px;
    width: max(300px, 100%);
  }
}
