.ticket-detail {
  height: 800px;

  .search-btn-custom {
    max-width: unset;
  }

  select:not(.select) {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: url('../../../../app/images/arrow-down-600.svg') no-repeat right center;
    cursor: pointer;

    &.quick-answer {
      width: 140px;
    }
    &.status {
      width: 90px;
    }
  }

  .update-ticket {
    height: 48px;
  }

  .info-body {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px 44px;
    align-items: center;

    .form-control--custom-select,
    .note {
      max-width: 350px;
      width: 100%;
    }

    .note {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      resize: none;
      height: 100px;
      border-radius: 10px;
      transition: border-color 0.2s linear;

      &:focus {
        border-color: var(--primary);
      }
    }
  }

  .product-list {
    .product-item {
      border-bottom: 0.5px dashed rgba(0, 0, 0, 0.3);
      &:first-child {
        padding-top: 0 !important;
      }

      &:last-child {
        padding-bottom: 0 !important;
        border-bottom: unset;
      }

      .ordered-qty {
        font-size: 13px;
        line-height: 17px;
        font-style: italic;
      }
    }
  }

  //   Help desk
  .help-desk {
    overflow: hidden;
  }

  .help-desk-heading {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .left {
      p {
        font-size: 20px;
        line-height: 30px;
      }

      svg {
        font-size: 22px;
      }
    }
  }

  .help-desk-body {
    overflow: auto;
  }

  .help-desk-message {
    width: 100%;

    &.admin {
      margin-left: auto;
      flex-direction: row-reverse;

      .mess-info {
        text-align: end;
      }

      .mess-heading {
        display: flex;
        flex-direction: row-reverse;
      }

      .mess {
        margin-left: auto;
        border-radius: 20px 0px 20px 20px;
        background: #f5f7fb;
      }
    }

    .mess-heading {
      max-width: 100%;

      > * {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .time-at {
        font-size: 12px;
        line-height: 21px;
      }
    }

    .mess {
      a {
        word-break: break-all;
      }
    }

    .mess-info {
      width: calc(100% - 40px);
    }

    .image-attachment {
      max-width: min(400px, calc(100% - 24px));
      border-radius: 5px;
      object-fit: contain;
    }

    .avatar {
      $size: 25px;
      width: $size;
      height: $size;
      object-fit: cover;
      flex-shrink: 0;
      border-radius: 50%;

      &:not(.no-avatar) {
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
      }

      &.no-avatar {
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

        svg {
          font-size: 12px;
        }
      }
    }

    .mess {
      background: #ebf1fd;
      border-radius: 0px 20px 20px 20px;
      width: 100%;
    }
  }

  .help-desk-footer {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    .heading {
      margin-bottom: 10px;

      .actions {
        .add-link {
          transition: color 0.2s linear;

          &:hover {
            color: var(--primary-hover);
          }
        }

        > * {
          padding-left: 16px;
          margin-right: 16px;
          border: transparent;
          outline: none;
          border-left: 1px solid rgba(0, 0, 0, 0.3);

          &:first-child {
            padding-left: 0;
            border-left: none;
          }

          &:last-child {
            margin-right: 0px;
          }
        }

        svg.arrow-down {
          position: relative;
          top: -3px;
        }
      }
    }

    .footer-edit {
      .attachment {
        background-color: #d8d7d7;
        display: inline-block;
      }

      .plane-icon {
        $size: 40px;
        width: $size;
        height: $size;
        flex-shrink: 0;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: var(--white);
        border-radius: 50%;
        transition: background-color 0.2s linear, box-shadow 0.2s linear;

        &:disabled {
          opacity: 0.6;
          cursor: default;
          background-color: var(--disabled-color);
          user-select: none;
          pointer-events: none;

          svg {
            color: rgba(0, 0, 0, 0.6);
          }

          &:hover {
            background-color: none !important;
          }
        }

        &:hover {
          background-color: #f5f7fb;

          svg {
            color: black;
          }
        }

        &:active {
          border: 1px solid #180767;
          box-shadow: 0px 0px 0px 2px rgba(24, 7, 103, 0.1);
        }
      }

      .message {
        caret-color: var(--primary);
        outline: none;
        border: none;
        cursor: auto;
        width: 90%;
        padding-right: 16px;
        max-height: 140px;
        overflow: auto;

        &:empty:before {
          user-select: none;
          pointer-events: none;
          color: rgba(0, 0, 0, 0.6);
          content: attr(data-placeholder);
        }
      }
    }
  }

  .close-icon {
    transition: color 0.2s linear;

    &:hover {
      color: var(--danger);
    }
  }

  // Popup
  .content-popup {
    border-radius: 10px;
    width: 566px;
    max-width: 100%;
    max-height: calc(100vh - 24px);
    height: 885px;
    overflow: auto;

    .heading .heading-edit {
      transition: color 0.2s linear;
      &:hover {
        color: var(--primary-hover);
      }
    }

    .nav {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #d9d9d9;
      }

      .nav-item {
        border-bottom: 2px solid transparent;
        z-index: 1;
        color: rgba(0, 0, 0, 0.6);

        &.active {
          color: var(--primary);
          border-color: var(--primary);
        }
      }
    }

    .note-add-quick-answer {
      font-size: 12px;
      line-height: 18px;
    }

    .heading {
      .title {
        font-size: 24px;
        line-height: 36px;
      }
    }

    .footer-add-new-quick-answer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      .button {
        height: 46px;
      }
    }

    .select-active {
      height: 46px;
    }

    .desc-textarea {
      resize: none;
      min-height: 100px;
      overflow: auto;
      transition: all 0.2s linear;

      &:focus {
        box-shadow: none;
        border-color: var(--primary);
      }
    }

    .quick-answer-list {
      min-height: 200px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }
    }

    .quick-answer-item {
      position: relative;

      .body {
        flex-grow: 1;
        overflow: hidden;

        .desc {
          text-overflow: ellipsis;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      .actions {
        position: relative;

        .action-list {
          content: '';
          position: absolute;
          top: calc(100% + 4px);
          right: 4px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          z-index: 1;
          width: 124px;

          &.disabled {
            background-color: #f8f8f8 !important;
            user-select: none;

            * {
              cursor: default;

              &:hover {
                background-color: transparent;
              }
            }
          }
        }

        .action-item {
          transition: background-color 0.2s linear;
          &:hover {
            background-color: #f5f7fb;
          }
        }
      }
    }
  }

  .nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1440px) {
  .ticket-detail {
    .help-desk-message {
      .mess-heading {
        flex-direction: column !important;
        row-gap: 4px !important;
      }
      &.customer {
        .mess-heading {
          align-items: flex-start !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .ticket-detail {
    height: auto;

    select {
      &.quick-answer {
        width: 125px;
      }
      &.status {
        width: 75px;
      }
    }

    .info-body {
      gap: 16px 28px;
    }

    .help-desk-message {
      &.admin {
        .mess-heading {
          align-items: flex-end !important;
        }
      }

      .mess-heading {
        flex-direction: column !important;
        row-gap: 4px !important;
        text-align: start !important;
        align-items: unset !important;
      }
    }

    //   Product list
    .product-wrap {
      max-height: 400px;
    }
  }
}
