.dashboard {
  .content {
    .title {
      font-size: 28px;
      line-height: 42px;
    }

    .sub-title {
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.6);
    }

    .actions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: fit-content;
      column-gap: 8px;

      .btn-action {
        padding-block: 12px;
        border: 1px solid currentColor;
        line-height: 15px;
        box-shadow: none !important;

        &.no-active {
          border-color: #c4c6c9;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

          &:hover {
            border-color: #1a25ab;
            background-color: #1a25ab;
            color: var(--white);
          }
        }
      }
    }
  }

  .content-wrap {
    border-radius: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
  }

  #best-seller-table {
    .separator {
      background-color: rgba(0, 0, 0, 0.2);
      height: 1px;
    }

    tbody tr:nth-child(even) {
      background-color: #f5f9fe;
    }

    th,
    td {
      font-size: 14px;
      line-height: normal;
      padding: 18px 15px;
      a {
        color: var(--primary);
        text-decoration: none;
      }
    }

    th {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }

    td {
      border: none;
    }
  }

  .total-sales {
    background: var(--primary);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 32px 24px;

    &__list {
      display: grid;
      grid-template-columns: auto auto;
      gap: 8px;
    }

    &__item {
      overflow: hidden;
      position: relative;

      .price {
        font-size: 35px;
        line-height: 1.5;
        color: #fff;
        margin: 0;
      }

      .title {
        font-size: 16px;
        line-height: 24px;
        color: #fff;
      }

      .chart-img {
        position: absolute;
        bottom: 0;
        right: 32px;
      }
    }
  }

  .sales-statistics {
    .chart-wrap {
      overflow: hidden;
    }
    .chart-wave {
      min-height: 200px;
    }
    .profit {
      * {
        white-space: nowrap;
      }

      .title {
        font-size: 20px;
        line-height: 24px;
      }

      .price {
        font-size: 35px;
        line-height: 1;
      }

      .time {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .refunds {
    .chart-wrap {
      $size: 300px;
      width: $size;
      height: $size;
      position: relative;

      canvas {
        position: relative;
        z-index: 1;
      }

      .total {
        position: absolute;
        top: 50%;
        left: calc(50%);
        transform: translate(-50%, -50%);
        z-index: 0;
        width: 65%;

        .qty {
          font-size: 48px;
          line-height: 72px;
        }
      }
    }

    .info {
      .item {
        .rect {
          $size: 20px;
          width: $size;
          height: $size;
          border-radius: 5px;
          flex-shrink: 0;
          margin-right: 32px;
        }

        .qty {
          font-weight: 600;
          font-size: 28px;
          line-height: 42px;
          letter-spacing: 0.0015em;
        }

        .desc {
          font-size: 12px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }

  .product-sold {
    &.content-wrap {
      .chart-bar {
        height: 100%;
        width: 100%;
        max-height: 400px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .dashboard {
    .total-sales {
      padding: 24px 16px;

      &__item {
        .price {
          font-size: 28px !important;
        }
      }
    }
  }
}

@media (max-width: 1279px) {
  .dashboard {
    .refunds {
      .chart-wrap {
        $size: 240px;
        width: $size;
        height: $size;
      }
    }

    .sales-statistics {
      .profit {
        .price {
          font-size: 28px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .dashboard {
    .refunds {
      .chart-wrap {
        $size: 200px;
        width: $size;
        height: $size;

        .total {
          .qty {
            font-size: 28px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
