@keyframes GO-BIGGER {
  0% {
    font-size: 12px;
    line-height: 16px;
  }

  100% {
    font-size: 35px;
    line-height: 43px;
  }
}

@keyframes DISPLAY-MENU {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navigator {
  float: left;
  .navigator--items {
    padding-left: 0;
  }
  .item-wrapper {
    position: relative;
    animation: DISPLAY-MENU 0.5s ease;
    float: left;
    overflow: hidden;

    .navigate-item {
      padding-left: 60px;
      padding-right: 32px;
      color: var(--white);

      &.active {
        padding-left: 55px;
        border-left: solid 5px var(--white);
        background: rgba(255, 255, 255, 0.2);
        color: var(--white);
        svg {
          color: var(--white);
        }
      }

      svg {
        color: var(--white);
        font-size: 14px;
      }

      &:hover {
        transition: all 0.2s linear;
        color: var(--white) !important;
        background: rgba(255, 255, 255, 0.2);

        svg {
          color: var(--white) !important;
        }
      }
    }
    .sub-menu-item {
      a {
        color: rgba(255, 255, 255, 0.6);
        &.active {
          border-left: none;
          background: none;
          color: var(--white);
          svg {
            color: var(--white);
          }
        }

        &:hover {
          background: none;
        }
      }
    }

    .sub-menu-item {
      width: 100%;
      border-left: solid 1px #ccc;
      padding-left: 24px;
      margin-left: 79px;
      li {
        padding-top: 10px;
        padding-bottom: 10px;
        list-style-type: none;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    a {
      color: var(--white);
      text-decoration: none;
      .menu-icon {
        display: inline-block;
        width: 35px;
      }
    }
  }

  .icon-wrap {
    width: 44px;
  }
}

.badge-new-ticket {
  padding: 3px 2px;
}

@media (min-width: 1440px) {
  .menu-icon {
    .fs-xxl-3 {
      font-size: 1.75rem !important;
    }
  }
}

@media screen and (max-width: 1550px) {
  .navigator {
    .item-wrapper {
      .navigate-item {
        padding-left: 32px;
        padding-right: 32px;
        &.active {
          padding-left: 28px;
        }
      }
      .sub-menu-item {
        margin-left: 50px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .navigator {
    &--items {
      justify-content: center;
    }
    .item-wrapper {
      .navigate-item {
        padding: 0.75rem 16px 0.75rem 24px !important;
        font-size: 14px !important;

        &.active {
          padding-left: 19px !important;
        }
      }
      .sub-menu-item {
        margin-left: 44px;
        padding-left: 0;

        .active {
          padding-left: 24px !important;
        }
      }
    }
  }
}
