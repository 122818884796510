// Config ckeditor
.ckeditor-wrap {
  width: 100%;
  max-width: 1000px;
  min-width: 300px;
}

.ck.ck-button {
  color: none !important;
}

.ck.ck-balloon-panel {
  display: none !important;
}

.ck.ck-editor {
  width: 100% !important;
}
.ck.ck-toolbar.ck-toolbar_grouping {
  border-radius: 10px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
  z-index: -1 !important;
}

.ck.ck-toolbar__items {
  display: flex !important;
  flex-wrap: wrap !important;
}

.ck-content.ck-editor__editable_inline {
  padding-inline: 12px !important;
  min-height: 300px;
  max-height: 600px;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-color: transparent !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-top-color: transparent !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;

  &.ck-focused {
    transition: all 0.2s linear;
    border-color: var(--primary) !important;
    box-shadow: none !important;
  }
}

#create-edit__product .header {
  .title {
    width: 140px;
    flex-shrink: 0;

    &.center {
      position: relative;
      top: 23px;
      transform: translateY(-50%);
    }

    &.no-width {
      width: unset;
    }
  }

  .attr-set-wrap {
    width: max(300px, 100%);
    max-width: 700px;
    min-width: 300px;
  }

  .store-list {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 12px 24px;
  }

  .form-input--custom {
    &.large {
      max-width: 700px;
      width: max(300px, 100%);
    }
  }

  .price-wrap {
    width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.4);
    height: 46px;
    overflow: hidden;
    transition: border-color 0.2s linear;
    will-change: border-color;

    &.tier {
      min-width: unset;
      width: 200px;
    }

    &.disable {
      background-color: var(--disabled-color);
      pointer-events: none;
    }

    &:focus-within {
      border-color: var(--primary) !important;
    }

    span {
      user-select: none;
      position: relative;

      &::before {
        $space: 13px;
        content: '';
        position: absolute;
        top: $space;
        bottom: $space;
        right: 0;
        width: 1px;
        background-color: currentColor;
      }
    }

    input {
      outline: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }
  }
  .done-wrap {
    color: var(--primary);
    position: relative;

    .button {
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: currentColor;
      opacity: 0.05;
    }
  }

  $width-price-type: 40%;
  .form-control-custom-select {
    width: $width-price-type;
  }

  .error-price {
    margin-left: calc($width-price-type + 16px);
  }

  .weight-wrap {
    background: var(--white);
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 10px;
    width: 300px;
    height: 46px;
    overflow: hidden;
    transition: border-color 0.2s linear;

    &.disable {
      background-color: var(--disabled-color) !important;
    }

    &:focus-within {
      border-color: var(--primary) !important;
    }

    span {
      position: relative;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.4);

      &::before {
        $space: 13px;
        content: '';
        position: absolute;
        top: $space;
        bottom: $space;
        right: 0;
        width: 1px;
        background-color: currentColor;
      }
    }
  }

  .form-control-short-desc {
    max-width: 1000px;
    min-width: 300px;
  }

  .adv-price {
    .content {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: #4285f4;
      }
    }

    .content-adv-price {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: fit-content;
      gap: 24px;

      .message {
        max-width: 300px;
      }

      input {
        transition: border-color 0.2s linear;
        will-change: border-color;
      }

      .title {
        width: fit-content !important;
      }
    }
  }

  .select[name^='website_id'],
  .select[name^='customer_group_id'] {
    min-width: 170px;
    padding-block: 11px;
  }

  input[type='number'],
  input[type='date'] {
    width: 300px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    outline: none;
    padding-block: 11px;

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }

    &:focus {
      border-color: var(--primary);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    &:disabled {
      background-color: var(--disabled-color);
      cursor: default;
    }
  }

  .adv-inventory {
    .content-adv-inventory {
      width: fit-content;
      margin-left: 80px;

      .title {
        width: fit-content;
        text-align: end;

        &.has-checkbox {
          position: relative;
          top: -14px;
        }
      }
    }
  }

  .mess-err {
    max-width: 300px;
  }

  .mess-err-tier {
    max-width: 180px;
    word-break: keep-all;
  }

  .close-icon {
    font-size: 24px;

    svg {
      transition: color 0.2s linear;
      will-change: color;
    }

    &:hover svg {
      color: var(--danger);
    }
  }

  .trash-icon {
    @extend .close-icon;
    font-size: 20px;
  }

  .form-control--custom-qty-tier {
    max-width: 200px !important;
  }

  .customer-group {
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .btn-add-customer-group {
    position: sticky;
    left: 0;
  }

  #table-adv-price {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);

    input {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      box-shadow: none;
      transition: border-color 0.2s linear;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }

      &:focus {
        border-color: var(--primary);
      }
    }

    thead tr:first-child {
      th:first-child {
        border-top-left-radius: 5px;
      }
      th:last-child {
        border-top-right-radius: 5px;
      }
    }

    tbody tr:nth-child(even) {
      background-color: #f5f9fe;
    }

    tr {
      th:first-child,
      td:first-child {
        padding-inline: 24px !important;
      }
      th:last-child,
      td:last-child {
        padding-inline: 24px !important;
      }
    }

    th,
    td {
      font-size: 14px;
      line-height: 17px;
      padding-inline: 8px;
      border-right: 1px dashed rgba(0, 0, 0, 0.2) !important;

      &:last-child {
        border-right: none !important;
      }
    }

    th {
      padding-block: 16px;
      border: none;
      background-color: var(--primary);
      color: var(--white);
      font-weight: 500;
    }

    td {
      padding-block: 16px;
      border: none;
      vertical-align: top;
    }
  }
}

@media (max-width: 1279px) {
  #create-edit__product .header {
    .adv-inventory {
      .content-adv-inventory {
        margin-left: 0;

        .title {
          max-width: 200px;
        }
      }
    }

    .form-control--custom-qty-tier {
      max-width: 150px !important;
    }
  }
}

@media (max-width: 1200px) {
  #create-edit__product .header {
    .weight-and-has-weight {
      flex-direction: column;
      row-gap: 16px;
      align-items: flex-end;
    }
  }
}
