.export {
  &-button {
    width: 40px;
    height: 40px;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: var(--primary-hover) !important;
    }
    svg {
      position: relative;
      top: 0;
    }
  }

  &-select {
    padding: 8px 16px 8px 17px;
    outline: none;
    border: none;
    font-size: 16px;
    line-height: 20px;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;

    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) 50%;

    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}

.filter-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
}

.report-tabs {
  &--items {
    color: #000;
    &.active {
      background: #f5f9fe;
      position: relative;
      overflow: hidden;
      color: var(--primary);
      &::after {
        position: absolute;
        content: '';
        width: 5px;
        height: 100%;
        background-color: var(--primary);
        left: 0;
        top: 0;
      }
    }
  }
}

.report-menu {
  width: 100%;
}

.filter-item {
  position: relative;
  &-delete {
    position: absolute;
  }
}
.bg-total {
  background-color: #d9e7fd !important;
}

@media screen and (max-width: 1440px) {
  .report-wrapper {
    .row {
      .col-12 {
        flex: 0 0 auto;
        width: 100%;
        .flex-row {
          flex-direction: row !important;
          .report-menu {
            .d-flex {
              display: flex !important;
            }
          }
          .report-export {
            min-width: 250px;

            .py-2 {
              padding-top: 0.5rem !important;
              padding-bottom: 0.5rem !important;
            }
            .d-flex {
              display: flex !important;
            }
            .mt-0 {
              margin-top: 0 !important;
            }
            .mx-2 {
              margin-right: 0.5rem !important;
              margin-left: 0.5rem !important;
            }
          }
        }
        .my-4 {
          margin-top: 1.5rem !important;
          margin-bottom: 1.5rem !important;
        }
      }
    }
  }

  .report-export {
    max-width: 350px;
  }

  .report-menu {
    width: fit-content;
  }

  .report-tabs {
    &--items {
      &.active {
        position: relative;
        overflow: visible;
        border-radius: 20px;
        &::after {
          position: absolute;
          content: '';
          width: 65%;
          height: 100%;
          background-color: transparent;
          left: 18%;
          top: 0;
          border-bottom: 3px solid var(--primary);
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 1100px) {
  .report-menu {
    .report-tabs--items {
      padding-inline: 12px !important;
      font-size: 14px !important;
      line-height: 1.5 !important;
    }
  }

  .report-export {
    span,
    select {
      font-size: 14px !important;
      line-height: 1.5 !important;
    }
  }
}

@media screen and (max-width: 1000px) {
  .report-heading.report-heading-mb {
    flex-direction: column !important;

    .report-tabs--items {
      flex: 1;
      text-align: center;
    }

    > * {
      width: 100%;
      max-width: unset;
    }

    select {
      flex-grow: 1 !important;
      margin-inline: 16px !important;
    }
  }
}
