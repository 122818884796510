.filter-section {
  input,
  select {
    box-shadow: none;
    outline: none;
    border: 1px solid #ccc;

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px white inset !important;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
    }

    &:focus {
      border-color: var(--primary) !important;
    }
  }
}

.input-field {
  position: relative;

  &__text-error {
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px;
  }

  input,
  select {
    --box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 12px 24px;
    background: #ffffff;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    outline: none;
    border: 2px solid #fff;
    transition: all 0.2s linear;

    &.has-icon {
      padding-right: 40px;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px white inset, var(--box-shadow) !important;
      -webkit-box-shadow: 0 0 0 1000px white inset, var(--box-shadow) !important;
    }

    // &:focus {
    //   border-color: var(--primary) !important;
    // }

    &:not(:placeholder-shown) + label,
    &:focus + label {
      top: 0;
      left: 15px;
      padding-inline: 8px;
      background-color: #ffffff;
      z-index: 1;
      color: var(--primary);
    }

    &.error {
      background: #fdf4f4;
      border-color: var(--danger);
      border-width: 2px;
    }

    &.error:not(:focus, :placeholder-shown) + label {
      color: var(--danger);
    }
    &.border-rd-30 {
      border-radius: 30px;
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.2s linear;
  }

  input,
  input:placeholder-shown,
  label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  input:placeholder-shown {
    color: rgba(0, 0, 0, 0.4);
  }

  .show-pwd,
  .hide-pwd {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }

  .pre-icon {
    position: absolute;
    top: calc(50% - 1rem);
    transform: translateX(-50%);
    left: 30px;
    color: #180767;
    font-size: 20px;
    + input {
      padding-left: 50px;
    }
  }

  // :focus {
  //   font-size: 16px;
  //   line-height: 20px;
  //   font-weight: 400;
  // }
}

input {
  &.bg-icon {
    background-repeat: no-repeat;
    background-position: 15px center;
    padding-left: 50px;
  }
  &.icon-user {
    background-image: url('../../../app/images/user.svg');
  }
  &.icon-key {
    background-image: url('../../../app/images/key.svg');
  }
}

.form-control.form-control--custom {
  height: 46px;
  border-color: rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  color: #1c1c1c;
  caret-color: var(--primary);
  transition: border-color 0.2s linear;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button {
    &:hover {
      cursor: pointer;
    }
  }

  &:focus {
    box-shadow: none;
    border-color: var(--primary) !important;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }

  &:disabled,
  &.disabled {
    --bs-secondary-bg: var(--disabled-color);
    transition: none !important;
    pointer-events: none;
    user-select: none;
  }

  &.form-control--custom-input-coupon {
    // max-width: 1000px;
    width: (900px, 100%);
  }

  // Style of textarea element
  &.form-control--custom-textarea {
    resize: none;
    min-height: 100px;
    max-width: 1000px;
  }
}
