@import 'base';

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lh-1-5 {
  line-height: 1.5 !important;
}

.lh-30 {
  line-height: 30px;
}

.lh-42 {
  line-height: 42px;
}

.fs-1,
.fs-2,
.fs-3,
.fs-4,
.fs-5,
.fs-6 {
  line-height: 1.5 !important;
}

.fs-7 {
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
}

.fs-8 {
  font-size: 0.5rem !important;
  line-height: 1.5 !important;
}

.fs-14 {
  font-size: 14px !important;
  line-height: 21px;
}

.fs-16 {
  font-size: 16px !important;
  line-height: 1.5 !important;
}

.mw-200px {
  max-width: 200px !important;
}

.mw-420px {
  max-width: 420px !important;
}

.text {
  &-dark-600 {
    color: rgba(0, 0, 0, 0.6);
  }
  &-dark-400 {
    color: rgba(0, 0, 0, 0.4);
  }
  &-dark-300 {
    color: rgba(0, 0, 0, 0.3);
  }
  &-dark-200 {
    color: rgba(0, 0, 0, 0.2);
  }
  &-link {
    color: $link;
  }
}

.seperate-line {
  display: block;
  width: 1px;
  height: 1px;
  &-dark-200 {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.overflow-x-auto {
  overflow-x: auto;
}

.wrapper {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: var(--white);
}

.container {
  max-width: 77.91%;
}

.fw-medium {
  font-weight: 500 !important;
}

.btn-circle {
  width: calc(2.40625rem + 2px);
  height: calc(2.40625rem + 2px);
}

.vertical-middle {
  vertical-align: middle;
}

.white-space-pre {
  white-space: pre;
}

a {
  text-decoration: none;
  &:hover {
    color: var(--bs-link-hover-color);
    text-decoration: underline;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.link-hover-primary {
  &:hover {
    color: var(--bs-link-color);
  }
}

.link {
  color: var(--link);
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  transition: color 0.2s linear;
  cursor: pointer;
  will-change: color;

  &:hover {
    color: var(--link-hover);
  }

  &:active {
    color: var(--primary-hover);
  }
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

button {
  // text-transform: uppercase;
  font-weight: 400;

  &.bg-primary {
    border: solid 1px var(--primary) !important;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  &.bg-primary:not([disabled]) {
    &:hover {
      background-color: var(--primary-hover) !important;
    }
  }

  // &[disabled] {
  //   background-color: var(--disabled) !important;
  //   border-color: var(--disabled) !important;
  //   cursor: not-allowed;
  // }

  &.cancel {
    background-color: var(--cancel) !important;
    &:hover {
      opacity: 0.8;
    }
  }
  .border-rd-25 {
    border-radius: 25px;
  }
  &.btn-apply {
    border: solid 1px var(--primary) !important;
    background-color: var(--primary) !important;
    text-transform: uppercase;
    &:hover {
      background-color: var(--primary-hover) !important;
      color: #fff !important;
    }
  }
  &.btn-cancel {
    border: solid 1px var(--primary) !important;
    text-transform: uppercase;
    background: transparent !important;
    color: var(--primary) !important;
    &:hover {
      background-color: var(--primary-hover) !important;
      color: #fff !important;
    }
  }

  &.btn-not-hover {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.btn-border-radius {
  padding: 13px 25px;
  border-radius: 25px;
}

.border-rd-25 {
  border-radius: 25px !important;
}

.border-rd-20 {
  border-radius: 20px;
}

.close-icon {
  $size: 24px;
  width: $size;
  height: $size;
  cursor: pointer;
  transition: color 0.2s linear;

  &:hover {
    color: var(--danger);
  }
}

.table-section thead tr td span {
  font-weight: 300;
}

.bg-white {
  &.wrapper,
  &.ticket {
    border-radius: 20px;
  }
}

.btn-outline-custom {
  outline: none;
  position: relative;
  color: #e22626;
  background-color: transparent;
  border: 1px solid currentColor;
  border-radius: 50rem;
  padding: 4px 12px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: currentColor;
    opacity: 0.05;
  }
}

.default-multiple-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
}

.two-line {
  @extend .default-multiple-line;
  -webkit-line-clamp: 2;
}

.three-line {
  @extend .default-multiple-line;
  -webkit-line-clamp: 3;
}

.four-line {
  @extend .default-multiple-line;
  -webkit-line-clamp: 4;
}

.loading-icon {
  animation: loading 0.8s ease-in-out infinite;
}

@media screen and (max-width: 1280px) {
  .btn-border-radius {
    padding: 13px;
  }
}

@media screen and (max-width: 1150px) {
  .text-lg-wrap {
    white-space: normal !important;
  }
}

@media screen and (max-width: 1024px) {
  $spacer: 0.75rem;

  .btn-border-radius {
    padding: 10px;
  }

  .w-lg-350px {
    width: 350px !important;
  }
}
