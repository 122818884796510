.title-display-inline {
  display: -webkit-inline-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 150px;
  top: 7px;
}

.btn-add-product {
  position: relative;
  height: 48px;
  width: 100%;
  max-width: 200px;

  a {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    transition: all 0.2s linear;
    font-size: 14px;
    line-height: 21px;
  }

  .toggle-select {
    /* border-left: 1px solid #313aa5; */
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    color: var(--white);
    transition: all 0.2s linear;
    padding-left: 0;
    padding-right: 24px;
  }
}

.menu-add-product {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  a {
    color: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    line-height: 21px;

    &:last-child {
      border: none;
    }
    &:hover {
      color: #000;
      background-color: #d9d9d9;
    }
  }
}

.logo-product-sm {
  $size: 40px;
  width: $size;
  height: $size;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  aspect-ratio: 1/1;
}

span.product-name {
  transition: color 0.2s linear;
  &:hover {
    color: var(--primary-hover);
  }
}

.edit-icon {
  &:hover {
    color: var(--primary-hover) !important;
  }
}

.trash-icon {
  &:hover {
    color: var(--primary-hover) !important;
  }
}

select {
  &.form-control {
    &.form-control-custom {
      background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 18px) 50%, calc(100% - 12px) 50%, calc(100% - 2.5em) 50%;
      background-size: 6px 6px, 6px 6px, 1px 24px;
      background-repeat: no-repeat;
    }
  }
}

.form-control {
  &.form-control-custom {
    // display: -webkit-flex;
    padding: 11px 0.75rem 11px 0.75rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    outline: none;
    color: #000;
    transition: color 0.2s ease, background-color 0.2s ease;

    &[type='date'][value=''] {
      color: rgba(0, 0, 0, 0.6);
    }

    &:focus {
      color: #000;
      background-color: #fff;
      outline: none !important;
      border: 1px solid --primary !important;
      box-shadow: none;
    }
  }
}

.filter {
  animation: SHOW-FILTER 0.5s linear;
  &-title {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 600;
  }
}

.btn-cancel {
  color: #4285f4;
}

// .striped-table {
//   tbody {
//     tr:nth-child(2n) {
//       background-color: #f5f9fe;
//     }
//     tr {
//       border-bottom: none;
//       &:last-child {
//         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//       }
//       &:hover {
//         background-color: #f0f0f0;
//       }
//       td {
//         padding: 16px 0;
//         border-bottom: none;
//         border-top: none;
//       }
//     }
//   }
// }

@keyframes SHOW-FILTER {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
