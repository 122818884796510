.app-container {
  width: 100%;
  padding: 0 60px;
}

a {
  &.text-white {
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    // display: flex;
    // align-items: center;
    svg {
      color: #fff;
    }
    :hover {
      color: #fff;
    }
  }
}

.sidebar-left {
  min-height: 100vh;
  height: 100%;
  position: fixed;
  width: 25%;
  top: 0;
  left: 0;
  bottom: 0;

  &-mini {
    position: fixed;
    width: 8.33333333%;
    top: 0;
    left: 0;
    bottom: 0;
  }
}

.footer {
  height: 140px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  .logout-icon a {
    transition: color 0.2s linear;
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #ffffffcc;
    }
  }

  svg {
    font-size: 16px;
  }
}

.copy-right {
  color: #f5f7fb;
  font-size: 12px;
  margin-top: 32px;
}

@media screen and (max-width: 1440px) {
  .app-container {
    padding: 0;
    overflow: hidden;
  }

  .header-wrapper {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 100%;
  }
  .copy-right {
    margin-top: 16px;
  }
  // .header .tooltips {
  //   right: 24px;
  // }
}

@media (max-width: 1700px) {
  .sidebar-left {
    &-mini {
      .logo {
        width: auto;
        height: 30px;
        object-fit: cover;
      }
    }
  }
}

@media (min-width: 1200px) {
  .sidebar-left {
    width: 16.6666667%;
  }
}

@media (max-width: 1200px) {
  .sidebar-left {
    &-mini {
      .logo {
        width: auto;
        height: 22px;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 1023px) {
  div.sidebar-left {
    position: sticky;
    top: 0;
    left: unset;
    width: 100%;
    height: unset;
    min-height: unset;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    padding-inline: 24px;

    &-mini {
      position: sticky;
      top: 0;
      left: unset;
      width: 100%;
      flex-direction: row !important;
      align-items: center;
      justify-content: space-between;
      padding-inline: 24px;
    }
  }

  .header.header-search {
    width: 100%;
    margin: 0;
  }

  div.logo-wrap {
    padding: 0 !important;
    margin-right: 16px;
  }

  .sidebar-wrap,
  .content-wrap {
    padding: 0 !important;
    width: 100% !important;
  }

  .footer {
    display: none;
  }

  .main-menu {
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .navigator {
    display: flex;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 2px !important;
    }

    .navigator--items {
      display: flex;

      .item-wrapper {
        height: 100%;
      }

      .item-wrapper .navigate-item.active {
        border-left: none;
      }
    }
  }
}
