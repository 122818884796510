.btn-square-light {
  $size: 35px;
  width: $size;
  height: $size;
  &:hover {
    background-color: #1a25ab !important;
    border-color: #1a25ab !important;
  }
}

.btn {
  &.btn-primary {
    &:hover {
      color: #fff !important;
      background-color: #1a25ab;
      border-color: #1a25ab;
    }
  }
  &.btn-update-password {
    font-size: 14px;
    line-height: 21px;
    min-height: 48px;
    padding: 13px 20px;
  }
  &.btn-update-profile {
    font-size: 14px;
    line-height: 21px;
    min-height: 48px;
    padding: 13px 25px;
  }
}

.form-control {
  &.form-control-solid {
    padding: 0.625rem 0.75rem;
    border-radius: 10px;
    border-color: #00000033;
    color: #1c1c1c;
    transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
    caret-color: var(--primary);
    &.is-invalid:focus {
      box-shadow: none;
    }
    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #8080c0;
      outline: 0;
      box-shadow: none;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px white inset !important;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
    }

    &:focus {
      border-color: var(--primary) !important;
    }

    &:disabled {
      transition: none;
      background-color: var(--disabled-color);
    }
  }
}

.border-upload-img {
  border: 1px dashed #000080;
  border-radius: 10px;
}

.btn-upload-img {
  $size: 40px;
  width: $size;
  height: $size;
  left: 92%;
  bottom: 35px;
  background-color: #fff !important;
  &:hover {
    background-color: #1a25ab !important;
    border-color: #1a25ab !important;
  }
}

.btn-remove {
  &:hover {
    .svg-inline--fa {
      &.border-primary {
        border-color: #fff !important;
      }
    }
  }
}

.flex-row-fluid {
  flex: 1 1 auto;
  min-width: 0;
}
.is-invalid + .show-pwd,
.is-invalid + .hide-pwd {
  right: 45px;
}
.show-pwd,
.hide-pwd {
  position: absolute;
  top: 14px;
  right: 25px;
}

.btn.btn-cancel-password {
  color: #4285f4;
  &:hover {
    color: #1a25ab;
  }
}

@media screen and (max-width: 1440px) {
  .btn {
    &.btn-update-password {
      padding: 13px 16px;
    }
    &.btn-update-profile {
      padding: 13px 16px;
    }
  }
}
