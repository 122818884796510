.nav-icon-1 {
  width: 33px;
  height: 29px;
  position: relative;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.nav-icon-1 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: var(--primary);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transition: 0.25s ease-in-out;
}

.nav-icon-1 span:nth-child(1) {
  width: 100%;
  top: 0px;
}

.nav-icon-1 span:nth-child(2) {
  width: 24px;
  top: 12px;
}

.nav-icon-1 span:nth-child(3) {
  width: 100%;
  top: 24px;
}

.nav-icon-1.open span:nth-child(1) {
  width: 100%;
  top: 12px;
  transform: rotate(135deg);
}

.nav-icon-1.open span:nth-child(2) {
  display: none;
}

.nav-icon-1.open span:nth-child(3) {
  width: 100%;
  top: 12px;
  transform: rotate(-135deg);
}

@media (max-width: 1023px) {
  .nav-icon-1 {
    display: none;
  }
}
