.select.form-control {
  transition: all 0.2s linear !important;
  padding-left: 12px;
  padding-right: 52px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #1c1c1c;
  border-radius: 10px;
  appearance: none;
  outline: none;
  background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 18px) 50%, calc(100% - 12px) 50%, calc(100% - 2.5em) 50%;
  background-size: 6px 6px, 6px 6px, 1px 24px;
  background-repeat: no-repeat;
  flex-shrink: 0;
  font-weight: 300;
  width: 300px;
  font-size: 1rem;
  line-height: 1.5;

  &:not([multiple]) {
    height: 46px;
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    cursor: default;
    background-color: var(--disabled-color);
    transition: none !important;
  }

  &.form-control--custom-input-coupon {
    max-width: 1000px;
    width: 900px;
  }
}
