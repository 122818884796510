.product-in-website {
  .title {
    width: 140px;
    flex-shrink: 0;
  }

  .website-checkbox-custom.disabled .checkmark {
    background-color: #dbdbdb;
    border-color: #bebebe;
  }
}
