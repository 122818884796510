.header-search {
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  align-items: center;
  padding: 24px 0;

  .sub-title-page {
    max-width: 500px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .search-area {
    svg {
      color: #999;
    }
  }

  .user-name {
    max-width: 350px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    svg {
      color: var(--bs-body-color);
      font-size: 14px;
      margin-left: 10px;
    }
  }

  input {
    border: 0;
    background: transparent;
    border-bottom: solid 1px transparent;
    &::placeholder {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.4);
    }
    &:focus-visible {
      border-bottom: solid 1px #ccc;
      outline: -webkit-focus-ring-color auto 0px;
    }
  }

  .menu-bar-search {
    align-items: center;
    height: 100%;
  }

  .tooltips {
    animation: DISPLAY-MENU 0.5s ease;
    position: absolute;
    top: calc(100% + 10px);
    right: -5px;
    width: 228px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding-block: 29.5px;
    color: var(--dark);
    z-index: 1;

    &--items {
      color: var(--dark);

      .icon-wrap {
        width: 36px;
      }

      svg {
        font-size: 20px;
      }

      span {
        display: inline-block;
        width: 40px;
      }
      &:hover {
        color: var(--primary-hover) !important;
      }
    }

    .logout-btn {
      background-color: #000;
      font-size: 13px;
      line-height: 16px;
    }
  }
}

.switch-store-btn {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: var(--white-color);
}

@media screen and (max-width: 1100px) {
  .header-search {
    .sub-title-page,
    .user-name {
      font-size: 14px !important;
      line-height: 1.5 !important;
    }
  }
}
