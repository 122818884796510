$xxl-size: 300px;
$xl-size: 250px;
$lg-size: 216px;
$md-size: 180px;

.mh-350px {
  max-height: 350px !important;
}

.mw-350px {
  max-width: 350px !important;
}

.avatar-img {
  object-fit: cover;
}

.thumbnail {
  overflow: hidden;
  width: $xxl-size;
  height: $xxl-size;
  &.overview {
    width: $lg-size;
    height: $lg-size;
  }
}

.avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.img-circle {
  border-radius: 100%;
}

.logo {
  width: 130px;
  height: 40px;
  object-fit: cover;
}

.logo-sm {
  width: 43px;
  height: 43px;
  object-fit: cover;
}

.title-inline {
  display: -webkit-inline-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 300px;
}

.btn-outline-primary.btn-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  min-width: 90px;
}

@media screen and (max-width: 1440px) {
  .thumbnail {
    overflow: hidden;
    width: $xl-size;
    height: $xl-size;
    &.overview {
      width: $md-size;
      height: $md-size;
    }
  }
  .h-xl-155px {
    height: 155px;
  }
}

@media screen and (max-width: 1199px) {
  .h-xl-155px {
    height: fit-content;
  }
}
