@import 'animation';

$enable-gradients: true;
$primary: #180767;
$primary_hover: #1a25ab;
$secondary: #87bdd8;
$cancel: #30393d;
$disabled: #999;
$success: #7fbc02;
$info: #b7d7e8;
$danger: #ed5348;
$warning: #ffbb38;
$dark: #000;
$white: #fff;
$link: #1a25ab;
$link-hover: #4285f4;
$disabled: #f0eeee;
$spacer: 1rem;
$enable-negative-margins: true;
$width-title-coupon: 150px;
$border-color: #e7e7e7;

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';

$custom-spacer: (
  12: $spacer * 0.75,
  19: $spacer * 1.1875,
  28: $spacer * 1.75,
  32: $spacer * 2,
  40: $spacer * 2.5,
);

$spacers: map-merge($spacers, $custom-spacer);

// Custom utilities
@import '../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../node_modules/bootstrap/scss/maps';
@import '../../../node_modules/bootstrap/scss/utilities';

$custom-widths: (
  2: 2%,
  5: 5%,
  10: 10%,
  12-5: 12.5%,
  15: 15%,
  20: 20%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  60: 60%,
  65: 65%,
  70: 70%,
  80: 80%,
  85: 85%,
  90: 90%,
  60px: 60px,
  150px: 150px,
  200px: 200px,
  300px: 300px,
  420px: 420px,
  500px: 500px,
  700px: 700px,
  fit-content: fit-content,
  unset: unset,
  initial: initial,
);

$custom-height: (
  150px: 150px,
  fit-content: fit-content,
);

$utilities: map-merge(
  $utilities,
  (
    'width':
      map-merge(
        map-get($utilities, 'width'),
        (
          values: map-merge(map-get(map-get($utilities, 'width'), 'values'), $custom-widths),
        )
      ),
    'height':
      map-merge(
        map-get($utilities, 'height'),
        (
          values: map-merge(map-get(map-get($utilities, 'height'), 'values'), $custom-height),
        )
      ),
  )
);

@import '~bootstrap/scss/bootstrap.scss';

.gy-20px {
  --bs-gutter-y: 1.25rem;
}

// Override toast
.swal2-container {
  z-index: 9999999 !important;
}

.swal-popup-custom {
  min-width: min(500px, 100%);
}

// Override animate react-loading-skeleton
.react-loading-skeleton {
  animation: changeColor 2s linear infinite;
}

.form-check-input {
  $size: 20px;
  box-shadow: none !important;
  width: $size;
  height: $size;
  flex-shrink: 0;
}

.form-check-label {
  font-size: 16px;
  line-height: 20px;
}
