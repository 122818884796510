.checkbox {
  &.checked {
    .checkbox-custom {
      background: var(--primary);

      &::before {
        left: unset;
        right: 1px;
        background-color: var(--white);
      }
    }
  }

  .checkbox-custom {
    width: 40px;
    height: 20px;
    background: rgba(0, 0, 128, 0.2);
    border-radius: 9999px;
    position: relative;

    &::before {
      $size: 18px;
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: $size;
      height: $size;
      border-radius: 50%;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      background-color: var(--primary);
    }
  }
}
