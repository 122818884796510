.content-success {
  border-radius: inherit;
  padding: 32px !important;
  max-width: 100%;
  width: 466px;

  img {
    $size: 94px;
    width: $size;
    height: $size;
    object-fit: contain;
  }

  .message {
    font-size: 20px;
    line-height: 24px;
    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .btn-custom {
    svg {
      margin-right: 14px;
    }
  }
}
