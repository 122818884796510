.ticket-wrap {
  .wrapper {
    border-radius: 5px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  }

  .ticket-item {
    min-width: 500px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    $separator: 1px solid #e7e7e7;

    .unread {
      color: #4285f4 !important;
    }

    .separator {
      width: 1px;
      height: 21px;
      background-color: #e7e7e7;
      margin-inline: 16px;
    }

    .heading {
      border-bottom: $separator;
      .left {
        .status-color {
          $size: 22px;
          width: $size;
          height: $size;
        }

        .ticket-id {
          font-size: 20px;
          line-height: 1.5;
        }
      }
    }

    .body {
      .desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }

    .ticket-item-footer {
      border-top: $separator;
    }
  }

  .menu {
    .menu-list {
      column-gap: 42px;
    }

    .menu-item {
      list-style: none;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: flex-end;
      column-gap: 12px;
      cursor: pointer;
      transition: color 0.2s linear;
      position: relative;

      &::before {
        $size-border: 3px;
        content: '';
        position: absolute;
        top: calc(100% - $size-border);
        left: 0;
        right: 0;
        height: $size-border;
        border-radius: 50rem;
        background-color: transparent;
        transition: background-color 0.2s linear;
      }

      &:hover,
      &.active {
        color: var(--primary);

        &::before {
          background-color: var(--primary);
        }
      }
    }
  }

  // .content-filter-ticket {
  //   width: 400px;
  //   max-width: calc(100% - 24px);
  //   height: 100%;
  //   border-radius: 0px 26px 26px 0px;
  //   animation: fadeIn 0.4s ease-in-out;
  // }
}

@media screen and (max-width: 1440px) {
  .ticket-wrap {
    .ticket-item {
      .desc {
        min-width: unset;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .ticket-wrap {
    .menu {
      .menu-list {
        column-gap: 24px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .ticket-wrap {
    .menu {
      .menu-list {
        column-gap: 24px;
      }

      .menu-item {
        .menu-icon {
          display: none;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
