.button {
  column-gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--white);
  padding: 12.5px 24px;
  border-radius: 50rem;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  text-transform: capitalize;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  transition: color 0.2s linear, background-color 0.2s linear, border-color 0.2s linear;

  &.btn-outline {
    background-color: transparent;
    border-color: var(--primary);
    color: var(--primary);
  }

  &:not(:disabled, .disabled):hover {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--white);
  }

  &:disabled,
  &.disabled {
    opacity: 0.65;
  }
}
